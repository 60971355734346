/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from 'react';

import {
  createStyles,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Theme,
  Paper,
  TableBody,
  Link,
} from '@material-ui/core';
import { Typography } from '@marketiq/marketiq-core-react';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import { linkAttributes } from '../../data/constants';

export type Props = {
  displayFields: BHPropertyFieldRule[];
  availabilities: any[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '16px',
      fontWeight: 400,
      wordWrap: 'break-word',
      '&:nth-of-type(even)': {
        backgroundColor: '#fbfcfc',

        '& .MuiTableCell-root:first-of-type': {
          background: '#e6eaea',
        },
      },
      '&:nth-of-type(odd)': {
        '& .MuiTableCell-root:first-of-type': {
          background: '#F5F7F7',
        },
        background: theme.palette.common.white,
      },
      verticalAlign: 'top',
    },
    availabilitiesText: {
      color: theme.palette.primary.main,
    },
    paperRoot: {
      boxShadow: 'none',
      marginTop: '16px',
    },
    stickyColumn: {
      position: 'sticky',
      zIndex: 1000,
      left: '0px',
      background: '#e6eaea',
    },
    tableHeader: {
      whiteSpace: 'pre-wrap',
      background: '#F5F7F7',
      fontSize: '16px',
      fontWeight: 500,
      '&>.MuiTableCell-root': {
        borderBottom: '1px solid #DFE6E5',
      },
    },
    noBottomBorder: {
      zIndex: 2,
      borderBottom: 'none',
      color: theme.palette.text.primary,
      whiteSpace: 'pre-line',
      width: '134px',
      maxWidth: '134px',
      padding: '4px',
      boxSizing: 'content-box',
      wordBreak: 'break-word',
      minWidth: '134px',
      verticalAlign: 'top',
      '&.MuiTableCell-root': {
        fontSize: '16px',
      },
      '&:first-of-type': {
        width: '188px',
        maxWidth: '188px',
        minWidth: '188px',
        zIndex: 100,
      },

      '@media(max-width:768px)': {
        width: '86px',
        maxWidth: '86px',
        minWidth: '86px',

        '&:first-of-type': {
          width: '188px',
          maxWidth: '188px',
          minWidth: '188px',
        },
      },
    },
    link: {
      wordWrap: 'break-word',
      fontSize: '16px',
    },
  }),
);

const PropertyAvailabilities: React.FC<Props> = ({
  displayFields,
  availabilities,
}) => {
  const containerRef: any = useRef();
  const displayAvailabilities =
    availabilities
      .filter((availability) => availability.status === 'ACTIVE')
      .sort((first: any, second: any) => first.order - second.order) || [];

  const classes = useStyles();

  function getAvailabilityFieldValues(fieldKey: string, availability: any) {
    return availability[fieldKey] || availability.data?.[fieldKey] || '-';
  }

  useEffect(() => {
    if (containerRef?.current) {
      containerRef?.current.scrollTo(0, 0);
    }
  }, [displayAvailabilities]);

  const emptyCells = (keyPrefix: string) => {
    const width = window.innerWidth;
    let numEmptyCol = 6 - displayFields.length;
    if (width <= 768) {
      numEmptyCol = Math.floor((width - 188) / 94) - displayFields.length;
    } else if (width <= 1024) {
      const value = Math.floor((width - 188) / 142);

      numEmptyCol = (value > 6 ? 6 : value) - displayFields.length;
    }

    return numEmptyCol > 0
      ? [...Array(numEmptyCol)].map((item, i) => (
        <TableCell
          key={`${keyPrefix}-empty-${i}`}
          classes={{ root: classes.noBottomBorder }}
          className="empty-cell"
        />
      ))
      : null;
  };

  return displayAvailabilities?.length > 0 ? (
    <>
      <Typography variant="h6" className={classes.availabilitiesText}>
        Availabilities
      </Typography>

      <Paper className={classes.paperRoot}>
        <TableContainer ref={containerRef} style={{ width: '100%' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: 'fixed' }}
          >
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell
                  key={'availabilityName'}
                  className={`${classes.noBottomBorder} ${classes.stickyColumn}`}
                >
                  Availability Name
                </TableCell>
                {displayFields.map((column: any) => (
                  <TableCell
                    key={column.fieldKey}
                    className={classes.noBottomBorder}
                  >
                    {column.fieldLabel}
                  </TableCell>
                ))}
                {emptyCells('table-header')}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayAvailabilities.map((row: any, index) => (
                <TableRow className={classes.root} tabIndex={-1} key={row.id}>
                  <TableCell
                    className={`${classes.noBottomBorder} ${classes.stickyColumn}`}
                    key={`${index}-availabilityName-cell`}
                  >
                    {getAvailabilityFieldValues('availabilityName', row)}
                  </TableCell>
                  {displayFields.map((column: any) => {
                    const value = getAvailabilityFieldValues(
                      column.fieldKey,
                      row,
                    )?.trim();
                    return (
                      <TableCell
                        className={classes.noBottomBorder}
                        key={`${column.fieldKey}-cell`}
                      >
                        {linkAttributes.includes(column.fieldLabel) &&
                        !!value ? (
                            <Link href={value} className={classes.link}>
                              {value}
                            </Link>
                          ) : (
                            <Typography variant="body1">
                              {value || '-'}
                            </Typography>
                          )}
                      </TableCell>
                    );
                  })}
                  {emptyCells(`${index}-table-content`)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  ) : null;
};

export default PropertyAvailabilities;

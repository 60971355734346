import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, createStyles, Theme, Grid, Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { externalTheme } from '@marketiq/marketiq-core-theme';
import { useHistory } from 'react-router-dom';
import { Property, PropertyPatch, Survey } from '../../types';
import * as mapUtil from '../../utilities/map';
import { surveyShortlistedOverride } from '../../utilities';
import { getFeatureImageForProperty } from '../../services/interactive/data-service';
import useInteractiveAPI from '../../services/interactive/useInteractiveAPI';
import { iconColorShortlist } from '../../map/constants/MapStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    '.cta-link': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontSize: '14px !important',
      fontWeight: '500 !important',
      cursor: 'pointer',
    },
    '.cta': {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none !important',
      marginTop: '30px',
      width: 'fit-content',
      background: 'transparent',
      border: 'none',
      paddingLeft: '0',
      paddingRight: '0',
      bottom: '10px',
      position: 'absolute',
    },
    '.cta .line-left': {
      content: '',
      display: 'block',
      width: '40px',
      height: '2px',
      backgroundColor: theme.palette.primary.light,
      transition: '0.4s all cubic-bezier(0.75, 0.02, 0.5, 1)',
      marginRight: '8px',
    },
    '.cta .line-right': {
      content: '',
      display: 'block',
      left: 0,
      top: 0,
      width: '0px',
      height: '0px',
      backgroundColor: theme.palette.primary.light,
      transition: '0.4s all cubic-bezier(0.75, 0.02, 0.5, 1)',
      marginRight: '0px',
    },
    '.cta .cta-link': {
      marginRight: '20px',
    },
    '.cta .long-arrow-right': {
      display: 'block',
      margin: '0px 12px',
      width: '18px',
      height: '10px',
      borderTop: '2px solid transparent',
      borderLeft: '2px solid transparent',
      transform: 'rotate(135deg) scale(0)',
      marginLeft: '20px',
    },
    '.cta .long-arrow-right:after': {
      content: '',
      display: 'block',
      left: 0,
      top: 0,
      width: '0px',
      height: '0px',
      backgroundColor: theme.palette.primary.light,
    },
    '.cta:hover .line-left': {
      width: 0,
      margin: 0,
      transition: '0.4s all cubic-bezier(0.75, 0.02, 0.5, 1)',
    },
    '.cta:hover .line-right': {
      display: 'block',
      width: '40px',
      height: '2px',
      transition: '0.4s all cubic-bezier(0.75, 0.02, 0.5, 1)',
    },
    '.cta:hover .long-arrow-right': {
      display: 'block',
      margin: '0px 12px',
      width: '10px',
      height: '10px',
      borderTop: `2px solid ${theme.palette.primary.light}`,
      borderLeft: `2px solid ${theme.palette.primary.light}`,
      transform: 'rotate(135deg)',
      transition: '0.4s all cubic-bezier(0.75, 0.02, 0.5, 1)',
      marginLeft: '-10px',
    },
    '.cta:hover .long-arrow-right:after': {
      content: '',
      display: 'block',
      width: '2px',
      height: '40px',
      backgroundColor: theme.palette.primary.light,
      transform: 'rotate(-45deg) translate(14px, 4px)',
      left: 0,
      top: 0,
    },
  },
  featureImg: {
    height: '336px',
    width: '480px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  thumbnailImg: {
    height: '118px',
    width: '200px',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    paddingTop: '5px',
    paddingLeft: '5px',
    marginBottom: '6px',
    '@media (max-width: 767px)': {
      width: '96px',
      height: '80px',
    },
  },
  fullsizeImg: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  circleWithImage: {
    background: 'rgba(9, 118, 195, 1)',
    color: 'white',
    border: '2px solid white',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleWithStar: {
    background: '#F9DD4B',
    color: 'white',
    border: '2px solid white',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryAddressFont: {
    fontSize: '17px',
    lineHeight: '16px',
    color: theme.palette.primary.main,
    marginBottom: '6px',
    fontFamily: 'Financier',
    '@media (max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
    },
  },
  secondaryAddressFont: {
    width: '200px',
    color: theme.palette.grey['800'],
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
    '@media (max-width: 767px)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
    },
  },
  shortlisted: {
    textAlign: 'right',
    color: externalTheme.palette.primary.contrastText,
    backgroundColor: externalTheme.palette.primary.main,
    textTransform: 'none',
    width: '82px',
    height: '24px',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '16px',
    position: 'absolute',
    padding: '0px -8px 0px 0px',
    bottom: '60px',
    '& .MuiChip-label': {
      paddingLeft: '8px',
    },
    '&:focus': {
      backgroundColor: externalTheme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: externalTheme.palette.primary.main,
    },
  },
  shortlist: {
    textAlign: 'right',
    color: '#003F2D',
    backgroundColor: '#e0e0e0',
    textTransform: 'none',
    width: '82px',
    height: '24px',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '16px',
    borderRadius: 16,
    position: 'absolute',
    bottom: '60px',
    '& .MuiChip-label': {
      paddingLeft: '8px',
    },
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: '#003F2D',

    },
    '&:focus': {
      backgroundColor: '#e0e0e0',
    },
  },
  chip: {
    width: '10.26px',
    height: '7.82px',
    backgroundColor: externalTheme.palette.primary.main,
    color: externalTheme.palette.primary.contrastText,
  },
  chipShortList: {
    width: '12px',
    height: '12px',
    backgroundColor: '#e0e0e0',
    color: externalTheme.palette.primary.main,
  },
  chipRoot: {
    width: '200px',
    '@media (max-width: 767px)': {
      width: '96px',
      marginBottom: '20px',
    },
  },
}));

const PropertyFeatureImageForComparision: React.FC<Props> = ({
  propertyId,
  survey,
  size,
  fullAddressStr,
  name,
  resetProperties,
  shortlistToggle,
}) => {
  const classes = useStyles();
  const [imageData, setImageData] = useState<any>({});
  const { getIconData } = mapUtil.default;
  const smallPlaceholderImg = '/assets/images/image-placeholder-small.jpg';
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const propertyImg = await getFeatureImageForProperty(survey.id, propertyId, 'IMAGES');

      let imgURL = propertyImg?.cloudfrontUrl || '';
      if (propertyImg?.origin !== 'BH') {
        imgURL += '?d=web';
      }
      const image = propertyImg ? {
        id: propertyImg.mediaId,
        url: imgURL,
        displayText: propertyImg.displayName,
        active: !propertyImg.isHidden,
        primary: false,
        imgClass: classes.thumbnailImg,
      } : {};
      const url = image?.url || smallPlaceholderImg;
      let imgClass = classes.thumbnailImg;
      if (size === 'fullsize') {
        imgClass = classes.fullsizeImg;
      }
      setImageData(
        {
          ...imageData,
          url,
          imgClass,
        },
      );
    })();
  }, [propertyId, survey.id]);

  const property = useMemo(() => {
    let propertiesFiltered: any[] = [];

    propertiesFiltered = survey?.showCurrentLocation
      ? survey?.properties?.filter((item: any) =>
        surveyShortlistedOverride(shortlistToggle, item),
      )
      : survey?.properties?.filter(
        (item: any) =>
          surveyShortlistedOverride(shortlistToggle, item) &&
            !item.metaData?.isCurrentLocation,
      );

    return propertiesFiltered?.find((item: any) => item.id === propertyId);
  }, [propertyId, survey]);

  const navigateToProperty = () => {
    if (property?.metaData?.isCurrentLocation) {
      return;
    }
    history.push(`/${survey.id}/property/${propertyId}`);
  };

  const getPropertyIndex = () => {
    if (survey?.properties) {
      return survey.properties
        .filter(
          (item: Property) =>
            !item.isHidden &&
            !item.metaData?.isCurrentLocation &&
            surveyShortlistedOverride(shortlistToggle, item),
        )
        .findIndex((item: Property) => item.id === propertyId);
    }
    return 1;
  };

  const { updateInteractiveProperty } = useInteractiveAPI(survey.id);

  const shortListClicked = () => {
    if (property) {
      if (!property.metaData) {
        property.metaData = {};
      }
      property.metaData.isClientShortlist =
        !property.metaData?.isClientShortlist;
      updateInteractiveProperty(survey, property as PropertyPatch, survey.id);
      resetProperties();
    }
    if (propertyListLength === 1) {
      window.localStorage.setItem('shortlisted', 'false');
    }
  };

  const propertyListLength = survey.properties.filter(
    (item: Property) =>
      !item.isHidden && surveyShortlistedOverride(shortlistToggle, item),
  ).length;

  const counterIndicator = property.metaData?.isCurrentLocation ? (
    <div
      className={classes.circleWithStar}
      style={{
        backgroundColor: property.metaData?.isClientShortlist
          ? iconColorShortlist
          : getIconData(property).iconColor,
      }}
    >
      <>&#9733;</>
    </div>
  ) : (
    <div
      className={classes.circleWithImage}
      style={{
        backgroundColor: property.metaData?.isClientShortlist
          ? iconColorShortlist
          : getIconData(property).iconColor,
      }}
    >
      {getPropertyIndex() + 1}
    </div>
  );

  const shortlistMarkup = property.metaData?.isClientShortlist ? (
    <Grid container justifyContent="flex-end">
      <Chip
        label="Shortlisted"
        size="medium"
        icon={<CheckIcon className={classes.chip} />}
        className={classes.shortlisted}
        onClick={shortListClicked}
      />
    </Grid>
  ) : (
    <Grid container justifyContent="flex-end">
      <Chip
        label="Shortlist"
        size="medium"
        icon={<AddIcon className={classes.chipShortList} />}
        className={classes.shortlist}
        onClick={shortListClicked}
      />
    </Grid>
  );
  return (
    <>
      {property && (
        <>
          <div
            role="button"
            onClick={navigateToProperty}
            style={{
              backgroundImage: `url('${imageData.url}')`,
              pointerEvents: property.metaData?.isCurrentLocation
                ? 'none'
                : 'auto',
            }}
            className={imageData.imgClass}
          >
            {counterIndicator}
          </div>
          <Grid
            className="for-address"
            style={{
              marginTop: '15px',
            }}
          >
            <div className={classes.primaryAddressFont}>{name}</div>
            <div className={classes.secondaryAddressFont}>{fullAddressStr}</div>
          </Grid>
          <div className={classes.chipRoot}>{shortlistMarkup}</div>
          {
            <button className="cta" onClick={navigateToProperty}>
              <div
                className="line-left"
                onClick={navigateToProperty}
                role="button"
              ></div>
              <div
                className="cta-link"
                onClick={navigateToProperty}
                role="button"
              >
                View Details
              </div>
              <div
                className="line-right"
                onClick={navigateToProperty}
                role="button"
              ></div>
              <div
                className="long-arrow-right"
                onClick={navigateToProperty}
                role="button"
              ></div>
            </button>
          }
        </>
      )}
    </>
  );
};

export type Props = {
  propertyId: string,
  survey: Survey,
  size: string,
  fullAddressStr: string,
  name: string,
  resetProperties: Function,
  shortlistToggle: boolean,
};

PropertyFeatureImageForComparision.displayName = 'PropertyFeatureImageForComparision';
export default PropertyFeatureImageForComparision;

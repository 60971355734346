import {
  createStyles, Divider, Grid, makeStyles, Theme,
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@marketiq/marketiq-core-react';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Survey } from '../../types';
import { getFormattedDate } from '../../utilities';
import { getClientLogoForSurvey } from '../../services/interactive/data-service';

const TITLE_CLIENT_WIDTH = 347;
const TITLE_ClIENT_HEIGHT = 86;

const marginLeft = 56;
const marginLeftTab = 20;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: `${marginLeft}px`,
    color: theme.palette.common.white,
    width: '91.2%',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '20px',
    },
    justifyContent: 'flex-start',
    height: 'auto',
    '@media (max-width: 767px)': {
      height: 'auto',
      margin: '20px',
    },
  },
  name: {
    marginBottom: '54px',
  },
  title: {
    wordBreak: 'keep-all',
    whiteSpace: 'break-spaces',
    fontSize: '72px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '36px',
      lineHeight: '40px',
      '-webkit-line-clamp': 5,
      maxHeight: '200px',
    },
    lineHeight: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 4,
    maxHeight: '320px',
    '@media (max-width: 767px)': {
      fontSize: '36px',
      lineHeight: '36px',
      maxHeight: '144px',
    },
  },
  subtitle: {
    marginTop: '60px',
    zIndex: 1,
    wordBreak: 'keep-all',
    '@media (max-width: 767px)': {
      marginTop: '16px',
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      with: '320px',
    },
  },
  coverDivider: {
    backgroundColor: 'white',
    margin: '0px',
    marginTop: '30px',
    zIndex: 100,
    padding: '1.75px',
    '@media (min-width: 767px)': {
      position: 'fixed',
      width: `calc(100% - ${2 * marginLeft}px)`,
      [theme.breakpoints.between('sm', 'md')]: {
        width: `calc(100% - ${2 * marginLeftTab}px)`,
      },
    },
    '@media (max-width: 767px)': {
      width: '320',
      marginTop: '16px',
      padding: '0px',
    },
  },
  line1: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '-0.01em',
    lineHeight: '20px',
  },
  clientName: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    '@media (max-width: 767px)': {
      fontSize: '14px',
      lineheight: '21px',
    },
  },
  surveyDt: {
    display: 'inline-block',
    '@media (max-width: 767px)': {
      fontSize: '14px',
      lineheight: '21px',
    },
  },
  surveySeparator: {
    marginRight: '16px',
    display: 'inline-block',
    fontSize: '16px',
    '@media (max-width: 767px)': {
      lineHeight: '24px',
    },
  },
  surveyAuthorDtSec: {
    marginTop: '10px',
    '@media (max-width: 767px)': {
      marginTop: '4px',
    },
  },
  imgBox: {
    width: '60px',
    height: '11.93px',
    color: theme.palette.primary.contrastText,
    marginBottom: '54px',
    '@media (max-width: 767px)': {
      width: '48px',
      height: '11.93px',
    },
  },
  innerImg: {
    width: '100%',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto auto',
  },
  cbreLogo: {
    width: '48px',
    height: '11.93px',
    color: theme.palette.primary.contrastText,
    marginBottom: '54px',
  },
  clientLogoInnerImage: {
    backgroundColor: theme.palette.common.white,
    height: 'auto',
    padding: '4px',
    maxWidth: '125px',
    maxHeight: '125px',
    width: 'auto',
    '@media (max-width: 767px)': {
      maxWidth: '133px',
      maxHeight: '74px',
    },
  },
  logoMargin: {
    margin: '20px 20px 0px 0',
  },
}));

const CoverSummaryPreview: React.FC<Props> = (
  {
    survey,
  },
) => {
  const classes = useStyles();
  const [titleVariant, setTitleVariant] = useState('h2');
  const [logoUrl, setLogoUrl] = useState('');
  const getTitle = useRef<any>();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const title = getTitle.current;
    if (title.clientWidth <= TITLE_CLIENT_WIDTH || title.clientHeight === TITLE_ClIENT_HEIGHT) {
      setTitleVariant('h2');
    } else {
      setTitleVariant('h4');
    }
    getClientLogoForSurvey(survey.id,
    ).then((res) => {
      const findMedia = res.media.find((eachMedia: any) => eachMedia.surveyId === survey.id);
      setLogoUrl(findMedia?.cloudfrontUrl);
    })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  }, [survey.coverTitle, survey.id]);

  useEffect(() => {
    setIsImageLoaded(false);
  }, [survey?.isDisplayClientLogo]);

  const handleImgLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <Grid
      container direction="column" justifyContent="center"
      className={classes.root}>
      <Grid item>
        <Typography
          ref={getTitle}
          variant={titleVariant as Variant}
          className={classes.title}>
          {survey.coverTitle || 'Property Survey'}
        </Typography>
        <Divider variant="middle" className={classes.coverDivider} />
      </Grid>
      <Grid item className={classes.subtitle}>
        <Typography variant='body1' className={classes.line1}>
          {survey.coverSubTitle}
        </Typography>
        <div className={classes.surveyAuthorDtSec}>
          {survey.isDisplayClientName && survey.clientName !== ''
            && <span>
              <Typography variant="body2" className={survey.clientName ? classes.clientName : ''}>
                {survey.clientName}
              </Typography>
              <Typography variant="body2" className={classes.surveySeparator}>
                |
              </Typography>
            </span>
          }
          <Typography variant="body2" className={classes.surveyDt}>
            {survey.coverDate || getFormattedDate(new Date())}
          </Typography>
        </div>
      </Grid>
      {logoUrl && survey.isDisplayClientLogo &&
      <Grid item className={isImageLoaded ? classes.logoMargin : ''}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
onLoad={handleImgLoad} src={logoUrl} alt="Client Logo"
          className={isImageLoaded ? classes.clientLogoInnerImage : ''} />
      </Grid>}
    </Grid>
  );
};

export type Props = {
  survey: Survey;
};

CoverSummaryPreview.displayName = 'CoverSummaryPreview';
export default CoverSummaryPreview;

import React from 'react';
import {
  createStyles, Grid, makeStyles, Theme,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { Typography } from '@marketiq/marketiq-core-react';
import { SurveyContact } from '../../types';

export type Props = {
  surveyContact: SurveyContact;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  contactCard: {
    backgroundColor: '#CBD1D3',
    color: theme.palette.grey['800'],
    '@media (min-width: 767px)': {
      width: 'calc(100% - 8px)',
      margin: '10px',
      marginRight: '8px',
      borderRight: `1px solid ${theme.palette.info.main}`,
      paddingRight: theme.spacing(2),
    },
    '@media (max-width: 767px)': {
      // margin: '4px',
      margin: '8px 0px 0px -4px',
      borderBottom: `1px solid ${theme.palette.info.main}`,
    },
  },
  contactCardBottomBorder: {
    paddingBottom: theme.spacing(2),
    marginRight: '8px',
    marginBottom: '8px',
  },
  contactName: {
    color: theme.palette.primary.main,
    fontSize: '19px',
    lineHeight: '20px',
  },
  contactTitle: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    maxHeight: '48px',
  },
  contactIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    fontSize: '0.8rem',
  },
  contactIconText: {
    alignItems: 'center',
    '@media (max-width: 767px)': {
      paddingBottom: '8px',
    },
  },
  contactIconDisplay: {
    fontSize: '8.5px',
    lineHeight: '12px',
  },
  contactEmailPadding: {
    '@media (max-width: 767px)': {
      paddingBottom: '8px', // need to figure this out
    },
  },
}));

const InteractiveSurveyContactPreview: React.FC<Props> = ({ surveyContact }) => {
  const classes = useStyles();

  return (
    <div >
      {surveyContact && (
        <Grid
          container direction="column" justifyContent="flex-start"
          alignItems="flex-start" spacing={1}
          className={classes.contactCard}
        >
           {surveyContact.name ? <Grid
            item xs={12} xl={12}
            md={12}>
            <Typography variant="subtitle2" className={classes.contactName}>
              {surveyContact.name}
            </Typography>
          </Grid>
             : null}
          {surveyContact.title ? <Grid
            item xs={12} md={12}
            xl={12}>
            <Typography variant="body3" className={classes.contactTitle}>
              {surveyContact.title}
            </Typography>
          </Grid>
            : null}
           {surveyContact.phoneNumber ? <Grid
            className={classes.contactIconText}
            item container direction="row"
            xs={12}
          >
            <CallIcon className={classes.contactIcon} />
            <Typography variant="body4" className={classes.contactIconDisplay}>
              {surveyContact.phoneNumber}
            </Typography>
          </Grid>
             : null}
            <div className={classes.contactEmailPadding}>
                {surveyContact.email ? <Grid
            className={classes.contactIconText}
            item container direction="row"
            xs={12} md={12} xl={12}>
            <EmailIcon className={classes.contactIcon} />
            <Typography variant="body4" className={classes.contactIconDisplay}>
              {surveyContact.email}
            </Typography>
          </Grid>
                  : null}
          </div>
        </Grid>
      )}
    </div>
  );
};

InteractiveSurveyContactPreview.displayName = 'ContactPreview';
export default InteractiveSurveyContactPreview;
